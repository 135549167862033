/* Dont let the phone nubmers link style be overried for iOS */
.me-CKEditor,
.me-SiteInfo {
  a[href^='tel'] {
    color: inherit;
  }
}

// foundation 6 switch bug fix
body.popup {
  background: transparent !important;
}

.cboxIframe {
  background: transparent;
}

input:checked + label > .switch-active {
  display: block;
}
input:checked + label > .switch-inactive {
  display: none;
}

.no-bold {
  font-weight: 400;
}
.no-italic {
  font-style: normal;
}
.no-underline {
  text-decoration: none;
}

#source-order {
  display: flex;
  & > div {
    border: 1px dashed red;
    text-align: center;
    padding: 5px;
    flex: 1;
  }
}
//  // COLUMNS BLOCK
//  // =============
.columns > .me-iwrap:before {
  display: table;
  content: ' ';
}

.responsive-menu-trigger {
  cursor: pointer;
  width: 40px;
  &.thin {
    width: 30px;
  }
  .top,
  .mid,
  .bottom {
    background-color: #1d1b1c;
    display: block;
    height: $menu-bar-height;
    margin-bottom: $menu-bar-space;
    position: relative;
    width: 100%;
    &.thin {
      height: $menu-bar-thin-height;
      margin-bottom: $menu-bar-thin-space;
    }
  }
  .bottom {
    margin: 0 !important;
  }
  &.large {
    display: none;
  }
}
.drilldown {
  margin: 0;
  .nested.is-drilldown-submenu {
    margin-right: 0;
    margin-left: 0;
  }
}

//  // GALLERY BLOCK
//  // =============
.me-block.me-Gallery {
  img {
    display: block;
    width: 100%;
  }
  .column.item {
    padding: 0.9375rem;
  }
  .row.collapse > .column {
    padding: 0;
  }
  .item {
    &.none {
      padding: 0;
    }
    &.small {
      padding: 5px;
    }
    &.large {
      padding: 10px;
    }
  }
  .on-show-event.row[data-hide='true'] {
    display: none;
    @media screen and (min-width: 768px) {
      display: block;
    }
  }
}

.me-block.me-Map {
  position: relative;
  .mask {
    position: absolute;
    top: 0;
    bottom: 0;
    right: 0;
    left: 0;
  }
}

// Formatting for Block - Accordion
.me-block.me-Accordion,
.me-block.me-Faq {
  article {
    box-sizing: border-box;
  }
}

.faq details {
  margin-bottom: 5px;
}

details,
article {
  box-sizing: border-box;
}

summary.align-right {
  flex-direction: row-reverse;
  span {
    flex: 1;
  }
}
details {
  & > summary::-webkit-details-marker {
    display: none;
  }
  & > summary::before {
    content: '' !important;
  }

  & > summary {
    //display: flex !important;
    //align-items: center;
    display: table !important;
    vertical-align: middle;
    cursor: pointer;
    &:focus {
      outline: none;
    }
    & > * {
      display: table-cell;
      vertical-align: middle;
    }
    & > div {
      padding-right: 10px;
    }
    //& > span {flex: 1;}
    & > span {
      width: 100%;
    }
    & > div > i,
    & > i {
      margin: 0 10px;
      &::before {
        margin: 0 auto;
      }
      &:first-child {
        display: table-cell;
      }
      &:nth-child(2) {
        display: none;
      }
      &:nth-child(3) {
        display: none;
      }
    }
    input[type='text'] {
      @include input_field_reset();
    }
  }

  &[open] > summary {
    & > div > i:first-child,
    & > i:first-child {
      display: none;
    }
    & > div > i:nth-child(2),
    & > i:nth-child(2) {
      display: table-cell;
    }
  }
  &[loading] > summary > div > i {
    &:first-child {
      display: none;
    }
    &:nth-child(2) {
      display: none;
    }
    &:nth-child(3) {
      display: table-cell;
    }
  }
}

// Formatting for Block - Tab
.me-Tab > .me-block.me-Tab,
.me-Tab.me-block > .tab {
  font-size: 0;
  margin-top: 20px;
  position: relative;
  &:after {
    visibility: hidden;
    display: block;
    font-size: 0;
    content: ' ';
    clear: both;
    height: 0;
  }
  & > ul {
    list-style: outside none none;
    padding: 0;
    margin: 0;
    & > li {
      color: $METabFontColor;
      cursor: pointer;
      font-size: $METabFontSize;
      padding: $METabLargePadding;
      margin: 0 $METabSpacing;
      border: $METabBorderWidth solid $METabBorderColor;
      font-family: $METabFontFamily;
      & > * {
        display: inline-block;
        vertical-align: middle;
      }
      & > i {
        width: auto;
        max-width: 40px;
      }
      & > span {
        border-right: $METabSepWidth $METabSepStyle $METabSepColor;
        width: 100%;
      }
      input {
        @include input_field_reset();
        color: $METabFontColor;
        font-size: $METabFontSize;
      }
      @if $METabBgType == image {
        background-image: $METabBgImage;
      } @else {
        background-color: $METabBgColor;
      }
      & input {
        color: $METabFontColor;
      }
      &:last-child {
        margin-right: 0;
        & > span {
          border-right: 0;
        }
      }
      &.tab-active {
        @if $METabBgType == image {
          background-image: $METabActiveBgImage;
        } @else {
          background-color: $METabActiveBgColor;
        }

        h4 {
          font-weight: 700;
          color: $METabActiveFontColor;
        }
        input,
        span {
          font-weight: 700;
          color: $METabActiveFontColor;
        }
      }
    }
  }
  .me-Tab-title {
    color: $METabFontColor;
    cursor: pointer;
    font-size: $METabFontSize;
    padding: $METabPadding;
    margin: 0 $METabSpacing;
    border: $METabBorderWidth solid $METabBorderColor;
    border-bottom: 1px dashed #ffffff;
    display: block;
    @if $METabBgType == image {
      background-image: $METabActiveBgImage;
    } @else {
      background-color: $METabActiveBgColor;
    }
    input {
      @include input_field_reset();
    }
    i {
      font-size: 16px;
      display: inline-block;
      vertical-align: middle;
      margin-right: 5px;
    }
  }

  .me-Tab-content {
    padding: $METabContentPadding;
    border: $METabContentBorderWidth solid $METabContentBorderColor;
    border-top: 0;
    font-size: $MEDefaultFontSize;
    line-height: 1.5;
    background-color: $METabContentBgColor;
    @media screen and (max-width: 800px) {
      border-color: $METabContentBorderColor;
      border-top: 0;
    }
    @media #{$medium-up} {
      padding: $METabMediumContentPadding;
    }
    @media #{$large-up} {
      padding: $METabLargeContentPadding;
    }
  }
  &[data-direction='top'] {
    & > ul {
      & > li {
        text-align: center;
        border-top-left-radius: $METabRadius;
        border-top-right-radius: $METabRadius;
        border-bottom: 0;
        display: inline-block;
        margin: 0;
        font-size: $METabFontSize;
        color: $METabFontColor;
        input {
          text-align: center;
          width: 100%;
          @include input_field_reset();
          font-size: $METabFontSize;
          color: $METabFontColor;
        }
      }
    }
  }
  &[data-direction='bottom'] {
    padding-bottom: $METabBottomTabSpace;
    & > ul {
      position: absolute;
      bottom: $METabBottomTabPosition;
      left: 0;
      right: 0;
      & > li {
        text-align: center;
        border-bottom-left-radius: $METabRadius;
        border-bottom-right-radius: $METabRadius;
        border-top: 0;
        margin: 0;
        display: inline-block;
        input {
          text-align: center;
          width: 100%;
        }
      }
    }
  }
  &[data-direction='left'] {
    & > ul {
      float: left;
      width: 20%;
      & > li {
        text-align: center;
        width: 100%;
        input {
          text-align: center;
          width: 100%;
        }
        border-right: 0;
        border-top-left-radius: $METabRadius;
        border-bottom-left-radius: $METabRadius;
      }
    }
    .me-Tab-block {
      float: left;
      width: 80%;
    }
  }
  &[data-direction='right'] {
    & > ul {
      float: right;
      width: 20%;
      & > li {
        text-align: center;
        width: 100%;
        input {
          text-align: center;
          width: 100%;
        }
        border-left: 0;
        border-top-right-radius: $METabRadius;
        border-bottom-right-radius: $METabRadius;
      }
    }
    .me-Tab-block {
      float: right;
      width: 80%;
    }
  }
  &[data-icon='hide'] {
    & > ul > li i {
      display: none;
    }
  }
  @if $METabArrow == true {
    li {
      position: relative;
      .me-Tab-arrow {
        width: 0;
        height: 0;
        border-left: $METabArrowWidth solid transparent;
        border-right: $METabArrowWidth solid transparent;
        border-top: $METabArrowWidth solid $METabActiveBgColor;
        position: absolute;
        left: 50%;
        margin-left: -$METabArrowWidth;
        bottom: -$METabArrowWidth;
        display: none;
      }
      &.tab-active {
        .me-Tab-arrow {
          display: block;
        }
      }
    }
  }
}

// Formatting for Block - Anchor
.me-block.me-Anchor {
  position: relative;
  a {
    position: absolute;
    width: 100%;
    bottom: 0;
  }
}
.me-Anchor-item {
  border: 1px solid #e8e8e8;
  padding: 5px 10px;
  margin: 10px;
  min-height: 30px;
  width: 200px;
  flex-grow: 1;
  cursor: pointer;
  &.anchor-highlight {
    background-color: #e8e8e8;
    border: 1px solid #cccccc;
  }
}

// Formatting for Block - Form
body.bldform {
  background: transparent !important;
}
.me-Form-cover {
  position: absolute;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
}

.me-block.me-Form,
body.bldform {
  label {
    display: none;
  }
  .me-wrap-inputcheckbox,
  .me-wrap-inputradio,
  .me-wrap-inputcheck,
  .me-wrap-inputsubscribe,
  .me-FormRadioButtonGroup,
  .me-FormInputSubscription,
  .me-FormInputCheckbox,
  .radio-button {
    label {
      display: inline-block;
    }
  }
  .radio-label,
  .checkbox-label {
    display: inline-block;
  }
  &.show-label {
    label {
      display: block;
    }
    .me-wrap-inputdisableuser label {
      display: none;
    }
  }
}
.me-block.me-From iframe {
  width: 100%;
}
.me-block.me-Form {
  position: relative;
}
// {
//  label{display: none;}
//  .me-wrap-inputcheckbox,
//  .me-wrap-inputradio,
//  .me-wrap-inputcheck,
//  .me-wrap-inputsubscribe
//  {
//    label {display: inline-block;}
//  }
//  &.show-label label {display: inline-block;}
//}

.me-block.me-Form,
body[class*='MES'],
body.bldform,
.cart-address select {
  option {
    background-color: white;
    color: black;
  }
  input:not([type='submit']),
  textarea,
  select {
    box-shadow: none;
  }
  input[type='radio'],
  input[type='checkbox'] {
    margin-right: 10px;
  }
  &.button-left .me-wrap-inputsubmit {
    text-align: left;
  }
  &.button-center .me-wrap-inputsubmit {
    text-align: center;
  }
  &.button-right .me-wrap-inputsubmit {
    text-align: right;
  }
  &.button-full .me-wrap-inputsubmit > * {
    display: block;
    width: 100%;
  }
  @media #{$medium-up} {
    &.medium-button-left .me-wrap-inputsubmit {
      text-align: left;
    }
    &.medium-button-center .me-wrap-inputsubmit {
      text-align: center;
    }
    &.medium-button-right .me-wrap-inputsubmit {
      text-align: right;
    }
    &.medium-button-full .me-wrap-inputsubmit > * {
      display: block;
      width: 100%;
    }
  }
  @media #{$large-up} {
    &.large-button-left .me-wrap-inputsubmit {
      text-align: left;
    }
    &.large-button-center .me-wrap-inputsubmit {
      text-align: center;
    }
    &.large-button-right .me-wrap-inputsubmit {
      text-align: right;
    }
    &.large-button-full .me-wrap-inputsubmit > * {
      display: block;
      width: 100%;
    }
  }
  .me-wrap-inputsubscribe {
    margin-bottom: 10px;
  }
}

// Formatting for Block - Icon
.me-block.me-Icon {
  figure {
    margin: 0;
    i {
      &.text-left {
        margin: 0 auto 0 0;
        text-align: left;
      }
      &.text-center {
        margin: 0 auto;
        text-align: center;
      }
      &.text-right {
        margin: 0 0 0 auto;
        text-align: right;
      }
      @media screen and (min-width: 641px) {
        &.text-medium-left {
          margin: 0 auto 0 0;
          text-align: left;
        }
        &.text-medium-center {
          margin: 0 auto;
          text-align: center;
        }
        &.text-medium-right {
          margin: 0 0 0 auto;
          text-align: right;
        }
      }
      @media screen and (min-width: 1025px) {
        &.text-large-left {
          margin: 0 auto 0 0;
          text-align: left;
        }
        &.text-large-center {
          margin: 0 auto;
          text-align: center;
        }
        &.text-large-right {
          margin: 0 0 0 auto;
          text-align: right;
        }
      }
    }
  }
  i {
    display: block;
  }
}

//// Formatting for Block - FORM
.me-block.me-Form {
  .me-wrap-inputsubscribe,
  .me-wrap-inputcheck {
    display: flex;
    align-items: center;
    input {
      margin: 0 5px 0 0;
    }
  }
}

//// Formatting for CAROUSEL
.me-block.me-Carousel,
.me-block.me-Slider {
  position: relative;
  .slider-arrow {
    cursor: pointer;
    position: absolute;
    transform: translateY(-50%);
    display: flex;
    align-items: center;
    z-index: 1;
    &.slider-arrow-left {
      left: 5px;
    }
    &.slider-arrow-right {
      right: 5px;
    }
  }
  .dots,
  .slick-dots {
    left: 0;
    margin: 0 !important;
    button {
      margin: 5px;
      position: relative;
      &:before {
        content: '';
        display: block;
        position: absolute;
        transform: translate(-50%, -50%);
        top: 50%;
        left: 50%;
        opacity: 1;
      }
    }
  }
}

@include slider-mask();

.me-block.me-Slider {
  .slider-arrow {
    top: 50%;
  }
}

.me-block.me-Carousel {
  .slick-slide > div {
    width: 100%;
    display: flex;
  }
  .slick-track {
    display: flex;
  }
  &.auto-height {
    .slick-track {
      display: block;
    }
  }
  .slider-arrow {
    top: 50%;
    padding: 0 10px;
  }
  & > .slick-slider {
    &.slick-vertical {
      .slick-track {
        flex-direction: column;
      }
    }
    .slick-track {
      .slick-slide {
        height: auto;
        display: flex;
      }
      *::-ms-backdrop,
      .slick-slide {
        display: block;
      }
    }
    display: block;
    &.vertical {
      flex-direction: column;
      justify-content: center;
    }
    .item {
      //flex-grow: 1;
      &[data-pad='none'] {
        padding: 0;
      }
      &[data-pad='small'] {
        padding: 0 15px;
      }
      &[data-pad='large'] {
        padding: 0 20px;
      }
      img {
        margin: 0 auto;
        display: block;
      }
    }
    .me-Carousel-mask {
      position: absolute;
      top: 0;
      bottom: 0;
      left: 0;
      right: 0;
    }
  }
}

.abs100x100 {
  position: absolute;
  left: 0;
  top: 0;
  right: 0;
  bottom: 0;
}
.me-Image {
  [data-align='left'] {
    margin: 0 auto 0 0;
    display: block;
  }
  [data-align='center'] {
    margin: 0 auto;
    display: block;
  }
  [data-align='right'] {
    margin: 0 0 0 auto;
    display: block;
  }
  [data-align='full'] {
    width: 100%;
    display: block;
  }

  &.transition-Scale,
  &.transition-Rotate {
    overflow: hidden;
  }

  @media #{$medium-up} {
    [data-align-medium='left'] {
      margin: 0 auto 0 0;
      width: auto;
    }
    [data-align-medium='center'] {
      margin: 0 auto;
      width: auto;
    }
    [data-align-medium='right'] {
      margin: 0 0 0 auto;
      width: auto;
    }
    [data-align-medium='full'] {
      margin: auto;
      width: 100%;
    }
  }
  @media #{$large-up} {
    [data-align-large='left'] {
      margin: 0 auto 0 0;
      width: auto;
    }
    [data-align-large='center'] {
      margin: 0 auto;
      width: auto;
    }
    [data-align-large='right'] {
      margin: 0 0 0 auto;
      width: auto;
    }
    [data-align-large='full'] {
      margin: auto;
      width: 100%;
    }
  }
}

.me-Button {
  @each $size in $breakpoint-classes {
    @include breakpoint($size) {
      @each $align in (left, right, center, full) {
        @if $size != 'small' {
          @if $align == 'full' {
            &[data-align-#{$size}='#{$align}'] {
              text-align: center;
              .button {
                display: block;
              }
              button.button {
                width: 100%;
              }
            }
          } @else {
            &[data-align-#{$size}='#{$align}'] {
              text-align: $align;
              .button {
                display: inline-block;
                text-align: $align;
              }
            }
          }
        } @else {
          @if $align == 'full' {
            &[data-align='#{$align}'] {
              text-align: center;
              .button {
                display: block;
              }
              button.button {
                width: 100%;
              }
            }
          } @else {
            &[data-align='#{$align}'] {
              text-align: $align;
              .button {
                display: inline-block;
                text-align: $align;
              }
            }
          }
        }
      }
    }
  }
  a.button,
  button.button {
    margin: 0;
  }
}

.me-block.row,
.me-block > .row {
  @at-root .me-PanelCol,
    & {
    &.height-grow {
      flex-grow: 1;
    }

    &.height-screen {
      min-height: 100vh;
    }
  }
  &.content-align-middle {
    & > .column {
      justify-content: center;
    }
  }
  &.content-align-bottom {
    & > .column {
      justify-content: flex-end;
    }
  }
  .column {
    flex-grow: 1;
    display: flex;
    flex-direction: column;
  }
}

@media #{$medium-up} {
  .me-block.row.medium-reverse,
  .me-block.medium-reverse > .row {
    flex-direction: row-reverse;
  }
}

@media #{$large-up} {
  .me-block.row.large-reverse,
  .me-block.large-reverse > .row {
    flex-direction: row-reverse;
  }
}

.me-max-width,
.me-admin .me-max-width.row {
  max-width: $global-width;
  margin-left: auto;
  margin-right: auto;
}

.me-PanelCol {
  display: flex;
  flex-direction: column;
  position: relative;
  margin-left: auto;
  margin-right: auto;
  //& > .me-block {
  //z-index: 9;
  //}
  a.panel-link {
    display: block;
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    z-index: 1;
  }
  & > .row {
    flex-grow: 1;
  }
}

.me-block {
  width: 100%;
}

//Menu Style
//nav.me-Menu {
//  &.horizontal {
//    flex-direction: row;
//    & > .menu-item {
//      &:hover > .sub-menu,
//      &:hover > .pointer-wrap {
//        left: 50%;
//        transform: translateX(-50%);
//      }
//      &:hover > .pointer-wrap,
//      &:hover > .sub-menu {
//        border-top: 10px solid transparent;
//        & > .pointer {
//          width: 0;
//          height: 0;
//          border-left: 10px solid transparent;
//          border-right: 10px solid transparent;
//          position: absolute;
//          border-bottom: 10px solid toRgb(#000000, 0.5);
//          top: -10px;
//        }
//      }
//      & > .sub-menu .menu-item > .sub-menu,
//      & > .pointer-wrap .menu-item > .pointer-wrap {
//        transform: translateX(100%);
//        top: 0;
//        left: 0;
//        .pointer {
//          border: 0;
//        }
//      }
//    }
//  }
//  &.vertical {
//    flex-direction: column;
//    & > .menu-item {
//      width: 100%;
//      &:last-child {
//        border: 0 !important;
//      }
//      &:hover > .sub-menu,
//      &:hover > .pointer-wrap {
//        top: 50%;
//        left: 100%;
//        transform: translateY(-50%);
//      }
//      &:hover > .pointer-wrap {
//        border-left: 10px solid transparent;
//        .pointer {
//          top: 50%;
//          width: 0;
//          height: 0;
//          position: absolute;
//          left: -10px;
//          border-top: 10px solid transparent;
//          border-right: 10px solid toRgb(#000000, 0.5);
//          border-bottom: 10px solid transparent;
//          transform: translateY(-50%);
//        }
//      }
//    }
//  }
//  .menu-item {
//    flex: 0 0 auto;
//    position: relative;
//    a {
//      display: block;
//      padding: 5px;
//    }
//    & > .sub-menu,
//    & > .pointer-wrap {
//      display: none;
//      z-index: 10;
//    }
//    &:hover > .sub-menu,
//    &:hover > .pointer-wrap {
//      position: absolute;
//      display: block;
//      min-width: 300px;
//      text-align: center;
//      //z-index: 1;
//      .menu-item {
//        border-bottom: 1px solid #f2f2f2;
//      }
//      a {
//        color: white;
//      }
//    }
//    &:hover > .sub-menu > .menu-item,
//    &:hover > .pointer-wrap > .sub-menu > .menu-item {
//      background-color: toRgb(#000000, 0.5);
//    }
//  }
//}

.me-Faq > section {
  & > article {
    display: none;
  }
  & > a {
    display: block;
  }
}

.me-Quote {
  min-height: 65px;
  position: relative;
  .ql,
  .qr {
    position: absolute;
    font-size: 4em;
    //z-index: 0;
    opacity: 0.3333;
  }
  .ql {
    top: 0;
    left: 0;
  }
  .qr {
    bottom: 0;
    right: 0;
  }
  .quote-body,
  .quote-body p {
    padding-bottom: 0;
    position: relative;
    //z-index: 1;
  }
  .quote-author {
    margin-top: 1em;
    text-align: center;
    font-size: 1em;
    padding: 0;
    position: relative;
    //z-index: 1;
    &:empty {
      margin: 0;
    }
  }
}

.me-TextSlider {
  .item {
    text-align: center;
  }
}
.me-block.me-Hamle {
  position: relative;
}

//***********************
//File
.me-block.me-Files {
  .button.file {
    text-align: left;
  }
}
//***********************
// Share button style
.me-block.me-ShareButton {
  figure {
    margin: 0 auto;
    display: flex;
    a {
      margin: auto;
    }
    width: 30px;
    height: 30px;
    border-radius: 30px;
    a {
      color: white;
    }
    &.facebook {
      background-color: rgba(59, 89, 152, 0.6);
      &:hover {
        background-color: rgb(59, 89, 152);
      }
    }
    &.twitter {
      background-color: rgba(85, 172, 238, 0.6);
      &:hover {
        background-color: rgb(85, 172, 238);
      }
    }
    &.google {
      background-color: rgba(221, 75, 57, 0.6);
      &:hover {
        background-color: rgb(221, 75, 57);
      }
    }
    &.linkedin {
      background-color: rgba(0, 123, 181, 0.6);
      &:hover {
        background-color: rgb(0, 123, 181);
      }
    }
  }
}

/** SLIDER Module - show first slide **/
.me-Slider,
.me-Carousel {
  & > .mebuild-slider-container > .slick-slide:first-child {
    display: block;
    width: 100%;
  }
}

section.me-ContentArea {
  display: flex;
  flex-grow: 1;
  width: 100%;
  flex-direction: column;
}

.me-StickyPanel {
  transition: background-color 0.5s ease;
  &[data-type='head'] {
    top: 0;
  }
  &[data-type='foot'] {
    bottom: 0;
  }
  &[data-mode='0'] {
    position: relative;
    z-index: initial;
  }
  &[data-mode='1'] {
    position: sticky;
    top: 0;
    z-index: 10;
    margin-right: auto;
    margin-left: auto;
    left: auto;
    transform: translateX(0);
    //&.me-fixed{
    //  left: 50%;
    //  transform: translateX(-50%);
    //}
  }
  &[data-mode='2'] {
    position: fixed;
    z-index: 10;
    left: 50%;
    transform: translateX(-50%);
    //&.me-fixed {
    //  left: 50%;
    //  transform: translateX(-50%);
    //}
  }
  &[data-mode='3'] {
    position: absolute;
    z-index: 10;
    left: 0;
    right: 0;
    margin-left: auto;
    margin-right: auto;
  }
  @media #{$medium-up} {
    &[data-medium-mode='0'] {
      position: relative;
      z-index: initial;
    }
    &[data-medium-mode='1'] {
      position: sticky;
      top: 0;
      z-index: 10;
      margin-left: auto;
      margin-right: auto;
      left: auto;
      transform: translateX(0);
      //&.me-fixed {
      //  left: 50%;
      //  transform: translateX(-50%);
      //}
    }
    &[data-medium-mode='2'] {
      position: fixed;
      z-index: 10;
      left: 50%;
      transform: translateX(-50%);
      //&.me-fixed {
      //  left: 50%;
      //  transform: translateX(-50%);
      //}
    }
    &[data-medium-mode='3'] {
      position: absolute;
      z-index: 10;
      left: 0;
      right: 0;
      margin-left: auto;
      margin-right: auto;
    }
  }
  @media #{$large-up} {
    &[data-large-mode='0'] {
      position: relative;
      z-index: initial;
    }
    &[data-large-mode='1'] {
      position: sticky;
      top: 0;
      z-index: 10;
      margin-left: auto;
      margin-right: auto;
      left: auto;
      transform: translateX(0);
      //&.me-fixed {
      //  left: 50%;
      //  transform: translateX(-50%);
      //}
    }
    &[data-large-mode='2'] {
      position: fixed;
      z-index: 10;
      left: 50%;
      transform: translateX(-50%);
      //&.me-fixed {
      //  left: 50%;
      //  transform: translateX(-50%);
      //}
    }
    &[data-large-mode='3'] {
      position: absolute;
      z-index: 10;
      left: 0;
      right: 0;
      margin-left: auto;
      margin-right: auto;
    }
  }
}

.me-MultiCol,
.me-PanelCol {
  & > .me-iwrap.column {
    position: relative;
    & > .me-iwrap {
      display: flex;
      flex-grow: 1;
      flex-direction: column;
    }
  }
}

.not-email {
  font-size: 12px;
  margin-top: -10px;
  margin-bottom: 1rem;
  display: block;
  color: black;
}

//***********************
//SiteInfo
//***********************
[data-align='h'] {
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
}
[data-align='v'] {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}

.text-left {
  display: flex;
  justify-content: flex-start;
  align-items: center;
}
.text-center {
  display: flex;
  justify-content: center;
  align-items: center;
}
.text-right {
  display: flex;
  justify-content: flex-end;
  align-items: center;
}

.text-full {
  display: flex;
  justify-content: stretch;
  text-align: justify;
  align-items: center;
}

@media #{$medium-up} {
  .medium-text-left {
    display: flex;
    justify-content: flex-start;
    align-items: center;
  }
  .medium-text-center {
    display: flex;
    justify-content: center;
    align-items: center;
  }
  .medium-text-right {
    display: flex;
    justify-content: flex-end;
    align-items: center;
  }
  .medium-text-full {
    display: flex;
    justify-content: stretch;
    text-align: justify;
    align-items: center;
  }
}

@media #{$large-up} {
  .large-text-left {
    display: flex;
    justify-content: flex-start;
    align-items: center;
  }
  .large-text-center {
    display: flex;
    justify-content: center;
    align-items: center;
  }
  .large-text-right {
    display: flex;
    justify-content: flex-end;
    align-items: center;
  }
  .large-text-full {
    display: flex;
    justify-content: stretch;
    text-align: justify;
    align-items: center;
  }
}

// Video Block Settings
.video[data-align='left'] {
  text-align: left;
}
.video[data-align='center'] {
  text-align: center;
}
.video[data-align='right'] {
  text-align: right;
}
.video[data-align='full'] {
  text-align: left;
  img {
    display: block;
    width: 100%;
  }
}
figure {
  &.video_none {
    .caption {
      display: none;
    }
  }
  &.video_hover {
  }
  &.video_half {
  }
}

// Content Filter
.me-block.me-FilterContent {
  & > .mask + .me-iwrap {
    position: relative;
    //z-index: 4;
  }
  .placeholder {
    display: flex;
    justify-content: center;
    align-items: center;
    width: 100%;
    height: 100px;
    border: 1px solid #e8e8e8;
    margin: 10px auto;
    color: #ccc;
  }
}

.meb-content-filter__add_button {
  min-height: 4em;
  border: 1px solid rgba(127, 127, 127, 0.25);
  background: rgba(127, 127, 127, 0.1);
  color: white;
  text-align: center;
  display: flex;
  flex-direction: row;
  justify-content: center;
  cursor: pointer;
  i.fa {
    color: #4caf50;
  }
}
.meb-content-filter__add_button:hover {
  background: #fff;
  color: #888;
}

.meb-content-filter__item {
  position: relative;
  & a.panel-link {
    display: none !important;
  }
}
.meb-content-filter__item_overlay {
  position: absolute;
  top: 0;
  left: 0;
  height: 100%;
  width: 100%;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  background: rgba(0, 0, 0, 0.05);
  box-shadow: 10px 0 0 rgba(0, 0, 0, 0.05);
  opacity: 0.25;
  &:hover {
    opacity: 1;
  }
}

.meb-content-filter__item_button {
  color: #aaa;
  padding: 5px;
  width: 64px;
  text-align: center;
  cursor: pointer;
  background: rgba(255, 255, 255, 0.8);
  font-size: 12px;
  border: 1px solid #aaa;
  margin: 0 10px;
  &:hover {
    background: #888888;
    color: white;
  }
  & > * {
    display: block;
  }
}
// Credit Block

.me-Credit {
  label {
    margin-right: 10px;
  }
}

[v-cloak] {
  display: none;
}
